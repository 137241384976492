const countdown = () => {
    const finalDate = new Date("Octomber 20, 2024 12:00:00").getTime(); // Целевая дата

    const timer = setInterval(() => {
        const now = new Date().getTime(); // Текущее время
        const distance = finalDate - now; // Разница между целевой датой и текущим временем

        // Вычисление дней, часов, минут и секунд
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Вывод значений на страницу
        document.getElementById("days").innerText = days;
        document.getElementById("daysLabel").innerText = getDaysLabel(days);
        document.getElementById("hours").innerText = hours.toString().padStart(2, '0');
        document.getElementById("minutes").innerText = minutes.toString().padStart(2, '0');
        document.getElementById("seconds").innerText = seconds.toString().padStart(2, '0');

        // Когда время истекло
        if (distance < 0) {
            clearInterval(timer); // Остановка таймера
            document.querySelector('.home-content__clock').innerHTML = "<span class='subject'>Проект <span>успешно</span> запущен и теперь доступен для использования</span>"; // Сообщение об окончании
        }
    }, 1000); // Обновление каждую секунду (1000 мс)
};

// Функция для правильного склонения слова "Дней"
const getDaysLabel = (days) => {
    if (days % 10 === 1 && days % 100 !== 11) {
        return 'День';
    } else if (days % 10 >= 2 && days % 10 <= 4 && (days % 100 < 10 || days % 100 >= 20)) {
        return 'Дня';
    } else {
        return 'Дней';
    }
};

countdown();


var typed = new Typed('.changeTitle', {
    strings: ['помощник', 'продавец', 'оператор'],
    typeSpeed: 70,
    backSpeed: 70,
    backDelay: 2000,
    loop: true,
    loopCount: Infinity,
});